import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrmLoginHandleSuccessFunction } from 'common-module/auth';

import { RoutesConfig } from '~/config/routes.config';
import { UserModel } from '~/api/user/user.model';

export const loginHandleSuccess: CrmLoginHandleSuccessFunction = () => {
  const router = inject(Router);
  const route = inject(ActivatedRoute);

  return ({ user: crmUser }) => {
    const user = crmUser as UserModel;
    const { queryParams } = route.snapshot;

    if (user.roles.signature) {
      router.navigate([RoutesConfig.SIGNATURE]);
      return;
    }

    if (user.roles.selfservice) {
      router.navigate([RoutesConfig.SELF_SERVICE]);
      return;
    }

    if (user.roles.attendance) {
      router.navigate([RoutesConfig.ATTENDANCE]);
      return;
    }

    if (queryParams['returnUrl']) {
      router.navigateByUrl(queryParams['returnUrl']);
      return;
    }

    router.navigate(['/']);
  };
};
