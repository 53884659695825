import { CrmAppConfiguration } from 'common-module/core/types';
import { CrmTableConfig } from 'common-module/table';

import { environment } from '~/environments/environment';

export const appConfig: CrmAppConfiguration = {
  environment,
  modules: {
    table: {
      component: {
        emptyState: { title: 'generic.noData' },
      },
    } as CrmTableConfig,
  },
} as const;
