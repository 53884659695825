import { Pipe, PipeTransform } from '@angular/core';
import { crmRemovePunctuation } from 'common-module/core';

@Pipe({ standalone: true, name: 'highlightValue' })
export class HighlightValuePipe implements PipeTransform {
  public transform(value?: string, highlightValue?: string): any {
    if (!value || !highlightValue) {
      return value;
    }
    const index = crmRemovePunctuation(value)
      .toLowerCase()
      .indexOf(crmRemovePunctuation(highlightValue).toLowerCase());

    if (index >= 0) {
      let result = value.substring(0, index);
      result += `<strong>${value.substring(
        index,
        index + highlightValue.length,
      )}</strong>`;
      result += value.substring(index + highlightValue.length, value.length);
      return result;
    }
    return value;
  }
}
