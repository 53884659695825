import {
  CrmLayoutCollapseComponent,
  CrmLayoutLocaleComponent,
  CrmLayoutLogoComponent,
  CrmLayoutMenuComponent,
  CrmLayoutProfileComponent,
  CrmLayoutProfileItemKey,
  crmLayoutTopbarSidebar,
  CrmLayoutV2Config,
} from 'common-module/layout-v2';

import { LayoutCollapseProvider } from '~/crm/layout/layout-collapse.provider';
import { LayoutLogoProvider } from '~/crm/layout/layout-logo.provider';
import { LayoutChatComponent } from '~/crm/layout/layout-chat.component';
import { LayoutSearchComponent } from '~/crm/layout/search/layout-search.component';
import { LayoutMenuProvider } from '~/crm/layout/layout-menu.provider';
import { LayoutVersionComponent } from '~/crm/layout/version/layout-version.component';

import { RoutesConfig } from './routes.config';

export const layoutConfig: CrmLayoutV2Config = {
  ...crmLayoutTopbarSidebar,
  header: {
    fragments: [
      {
        key: 'collapse',
        content: CrmLayoutCollapseComponent,
        provider: LayoutCollapseProvider,
      },
      {
        key: 'logo',
        content: CrmLayoutLogoComponent,
        provider: LayoutLogoProvider,
      },
      {
        key: 'chat',
        content: LayoutChatComponent,
      },
      {
        key: 'search',
        content: LayoutSearchComponent,
      },
      {
        key: 'locale',
        content: CrmLayoutLocaleComponent,
      },
      {
        key: 'profile',
        content: CrmLayoutProfileComponent,
      },
    ],
  },
  siderLeft: {
    ...crmLayoutTopbarSidebar.siderLeft,
    collapsedWidth: 80,
    fragments: [
      {
        key: 'menu',
        content: CrmLayoutMenuComponent,
        provider: LayoutMenuProvider,
      },
      {
        key: 'version',
        content: LayoutVersionComponent,
      },
    ],
  },
  profile: {
    roleMap: {
      admin: 'Admin',
      management: 'Management',
      receptionist: 'Receptionist',
      doctor: 'Doctor',
      nurse: 'Nurse',
    },
    items: [
      {
        key: 'changelog',
        title: 'crm.changelog.header.title',
        route: RoutesConfig.CHANGELOG,
      },
      {
        key: CrmLayoutProfileItemKey.LOGOUT,
        title: 'generic.logout',
      },
    ],
    dropdownTrigger: 'click',
    dropdownIcon: 'down',
    dropdownIconVisible: 'up',
    dropdownPlacement: 'bottom',
  },
  collapse: {
    icon: 'menu-fold',
    iconCollapsed: 'menu-unfold',
  },
};
